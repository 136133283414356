<template>
  <div class="mt-4">
    <div>
      <!--table-->
      <vs-table :data="rows" stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" sort-key="nama_proyek">#</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_proyek">Item Pekerjaan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_proyek">Bobot</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_proyek">Bobot Selesai</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_proyek">Progress Selesai</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_proyek">Gambar</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.nama_item_progress_pekerjaan }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.bobot | idr }}%</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.bobot_reached_percentage | idr }}%</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.progress_percentage | idr }}%</vs-td>
            <vs-td class="whitespace-no-wrap">
              <vs-button type="border" size="small" icon-pack="feather" icon="icon-image" class="px-2 mr-2" @click="showImages(item.last_foto_kontraktor_url_list || [])">Lihat Foto</vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" type="border" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
      </div>
    </div>

    <!--modals-->
    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import SpkRepository from '@/repositories/proyek/spk-repository'
import ImageViewer from '@/views/components/image-viewer/ImageViewer'
import _ from 'lodash'

export default {
  name: 'TabPekerjaan',
  props: ['initData', 'isActive'],
  components: {
    ImageViewer
  },
  watch: {
    isActive (active) {
      if (active && this.isFirstActive) {
        this.getInitData()
        this.isFirstActive = false
      }
    }
  },
  data () {
    return {
      isFirstActive: true,
      rows: []
    }
  },
  methods: {
    getInitData () {
      this.isLoading = true

      const idSpk = this.initData.header.id_spk
      const params = { withLastFotoKontraktor: true }
      SpkRepository.getSpkPekerjaan(idSpk, params)
        .then(response => {
          this.rows = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    showImages (images) {
      const items = _.cloneDeep(images)
      this.$refs.viewer.show(items)
    },

    prev () {
      const currentActiveTab = this.$store.state.approvals.stkApproval.activeTab
      this.$store.commit('approvals/stkApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    }
  }
}
</script>
